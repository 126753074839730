if (document.getElementById('Vue__job-search')) {
    var vue = new Vue({
        el: '#Vue__job-search',
        data: {
            search_term: '',
            filter_by_business: '',
            business_filters: [],
            filter_by_city: '',
            city_filters: [],
            location_filters: [],
            filter_by_type: '',
            filter_by_contract: '',
            filter_by_location: '',
            type_filters: [],
            contract_filters: [],
            results: [],
            internal: 0,
            forceNoResultMesseage: 0,
            logSend: false
        },
        methods: {
            //zero reutrn search log ajax function
            zeroSearchLog: function () {
                var myself = this;
                if (!myself.logSend) {
                    myself.logSend = true;
                    $.ajaxSetup({
                        headers:
                            {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
                    });
                    $.ajax('/fuer-bewerber/search-log', {
                        method: 'post',
                        data: {
                            search_term: myself.search_term
                        }
                    });
                }
            },
            search: function () {
                var myself = this;

                $.ajax('/fuer-bewerber/search_jobs', {
                    method: 'get',
                    data: {
                        internal: myself.internal,
                        search_term: myself.search_term,
                        filter_by_business: myself.filter_by_business,
                        filter_by_city: myself.filter_by_city,
                        filter_by_type: myself.filter_by_type,
                        filter_by_contract: myself.filter_by_contract,
                        filter_by_location: myself.filter_by_location
                    }
                }).done(function (response) {
                    myself.logSend = false;
                    myself.results = response.jobs;
                    myself.business_filters = response.businesses;
                    myself.city_filters = response.cities;
                    myself.type_filters = response.types;
                    myself.contract_filters = response.contract;
                    myself.location_filters = response.location;
                    myself.forceNoResultMesseage = response.forceNoResultMesseage;

                    if (!myself.results.length) {
                        setTimeout(function () {
                            myself.zeroSearchLog();
                        }, 4000);
                    }
                });
            },
            getDetailUrl: function (url) {
                return url + '?search_term=' + encodeURIComponent(this.search_term)
                    + '&filter_by_business=' + encodeURIComponent(this.filter_by_business)
                    + '&filter_by_city=' + encodeURIComponent(this.filter_by_city)
                    + '&filter_by_type=' + encodeURIComponent(this.filter_by_type)
                    + '&filter_by_contract=' + encodeURIComponent(this.filter_by_contract)
                    + '&filter_by_location=' + encodeURIComponent(this.filter_by_location)
                    + '&internal=' + encodeURIComponent(this.internal);
            }
        },
        computed: {
            chunkedJobList: function () {
                if (_.size(this.results) > 1) {
                    return _.chunk(this.results, Math.ceil(_.size(this.results) / 2));
                } else {
                    return [
                        this.results,
                        []
                    ];
                }
            }
        },
        watch: {
            search_term: function (val) {
                this.search();
            },
            filter_by_business: function (val) {
                this.search();
            },
            filter_by_city: function (val) {
                this.search();
            },
            filter_by_type: function (val) {
                this.search();
            },
            filter_by_contract: function (val) {
                this.search();
            },
            filter_by_location: function (val) {
                this.search();
            }
        }
    });
}